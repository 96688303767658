<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <div class="text-center mb-3">
          <v-avatar size="150">
            <img alt="user" src="/images/Icon.png" />
          </v-avatar>
        </div>
        <v-card class="mx-auto text-center" max-width="500">
          <v-card-title>
            <div class="text-center mx-auto">
              <v-icon class="mr-2" color="black">mdi-alert</v-icon>
              <span>{{ $t("view.PageErrorPinChanged.title") }}</span>
            </div>
          </v-card-title>
          <v-card-text>{{ $t("view.PageErrorPinChanged.description") }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              @click="login"
              :to="{ name: 'PageAccessPin' }"
              color="primary"
              >{{ $t("action.loginAgain") }}</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),
  created() {
    //
  },
};
</script>
